import * as React from "react";

const Banner = () => {
    return (
        <section className="trip-banner-sec">
            <div className="trip-banner-heading">
            </div>
        </section>
    )
}
export default Banner;
